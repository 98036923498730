import React from 'react'

import ExitButton from 'components/ExitButton'

export const AlreadyConnectedError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Você entrou na consulta em outro dispositivo</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Saia dela para poder entrar por aqui.</p>
      </div>

      <div className='ConnectionError__button'>
        <ExitButton />
      </div>
    </div>
  )
}