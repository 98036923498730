import React from 'react'
import PropTypes from 'prop-types'

const Wifi = ({
  fill = '#000',
  height = '28.914',
  width = '38.112'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 38.112 28.914'>
    <g transform='translate(0)'>
      <path d='M19.058,3.782a22.9,22.9,0,0,0-15.6,6.06C1.406,11.749-1.449,9.087.857,7a27.148,27.148,0,0,1,36.4,0c2.3,2.083-.555,4.745-2.6,2.838A22.911,22.911,0,0,0,19.058,3.782Z' transform='translate(0)' fill={fill} />
      <path d='M14.885,4.943A15.781,15.781,0,0,0,4.4,8.866c-2.074,1.979-5-.566-2.782-2.686A19.843,19.843,0,0,1,14.885,1.161,19.827,19.827,0,0,1,28.15,6.18c2.22,2.12-.7,4.665-2.776,2.686A15.806,15.806,0,0,0,14.885,4.943Z' transform='translate(4.172 6.261)' fill={fill} />
      <path d='M9.872,6.184A7.169,7.169,0,0,0,5.054,8.042,1.906,1.906,0,1,1,2.5,5.258a10.943,10.943,0,0,1,14.745,0,1.906,1.906,0,1,1-2.558,2.783A7.154,7.154,0,0,0,9.872,6.184Z' transform='translate(9.185 12.884)' fill={fill} />
      <path d='M5.4,3.658A2.832,2.832,0,1,1,2.558,6.49,2.831,2.831,0,0,1,5.4,3.658Z' transform='translate(13.661 19.591)' fill={fill} fillRule='evenodd' />
    </g>
  </svg>

Wifi.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default Wifi
