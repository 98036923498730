import React, { useContext } from 'react'

import Button from 'components/Button'
import Delighted from 'components/Delighted'

import { MeetingContext } from 'contexts'
import { endpoints } from 'settings'
import NpsIcon from '../../static/imgs/nps.png'

export default function Nps() {
  const { meeting } = useContext(MeetingContext)
  const { user, guest } = meeting

  const content = {
    customer: {
      subtitle: 'Sua consulta foi realizada com sucesso! Por favor, avalie a qualidade do Terapeuta',
      link: endpoints.IQUILIBRIO_URL,
    },
    seller: {
      subtitle: 'Seu atendimento foi realizado com sucesso!',
      link: `${endpoints.IQUILIBRIO_URL}/seller/home`,
    },
  }

  const handleExitClick = () => window.location.assign(content[user.type].link)

  const isCustomer = user.type === 'customer' ? true : false;

  return (
    <section className="Nps">
      <img className="Nps__icon" src={NpsIcon} alt="NPS icon" />
      <h1 className="Nps__title">Consulta encerrada</h1>
      <p className="Nps__subtitle">{content[user.type].subtitle}</p>
      <Button id='exit' type='link' onButtonClick={() => handleExitClick()}>
        Voltar para Home
      </Button>

      {isCustomer && (
        <Delighted name={guest.name} email={guest.email} />
      )}
    </section>
  )
}
