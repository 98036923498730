import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import CallRoom from 'views/CallRoom'
import WaitingRoom from 'views/WaitingRoom'
import Nps from 'views/Nps'

import { views } from 'views/viewsCode'
import { MeetingContext } from 'contexts'

const Homepage = () => {
  const { hash } = useParams()
  const token = new URLSearchParams(window.location.search).get('token')

  const [meeting, setMeeting] = useState({})
  const [callRoom, setCallRoom] = useState(null)
  const [localTracks, setLocalTracks] = useState(null)
  const [view, setView] = useState(views.waitingRoom)

  const updateCallRoom = (data) => setCallRoom(data)
  const updateLocalTracks = (localTracks) => setLocalTracks(localTracks)
  const updateMeeting = (data) => setMeeting(prevMeeting => ({ ...prevMeeting, ...data }))

  return (
    <MeetingContext.Provider
      value={{ callRoom, localTracks, meeting, updateCallRoom, updateLocalTracks, updateMeeting, setView, hash, token }}>
      {
        view === views.callRoom ?
          <CallRoom /> :
          view === views.nps ?
            <Nps /> :
            <WaitingRoom />
      }
    </MeetingContext.Provider>

  )
}

export default Homepage