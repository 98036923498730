import React from 'react'
import PropTypes from 'prop-types'

const Check = ({
  fill = '#000',
  height = '50',
  width = '50'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 50 50'>
    <g transform='translate(-153 -433)'>
      <circle cx='25' cy='25' r='25' transform='translate(153 433)' fill={fill} />
      <path d='M28.182,21.35,15.87,35.577,8.483,29.284,5.2,33.115l11.217,9.849L32.012,24.633Z' transform='translate(159.317 426.104)' fill='#fff' />
    </g>
  </svg>

Check.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default Check
