/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react'

import Button from 'components/Button'
import ExitButton from 'components/ExitButton'
import CustomIcons, { iconList } from 'components/CustomIcons'
import Spinner from 'components/Spinner'

import { colors } from 'utils/stylesConstants'

export const ReadyToCallMessage = ({ action, loading }) => {
  return (
    <div className='FeedbackMessageManager'>
      <div className="FeedbackMessageBox">
        <div className="FeedbackMessageBox__icon">
          <CustomIcons icon={iconList.Check} fill={colors.GREEN} />
        </div>

        <div className="FeedbackMessageBox__title">
          <p>Tudo pronto!</p>
        </div>

        <div className='FeedbackMessageBox__message'>
          <p>{`Use o botão abaixo para iniciar\n sua consulta.`}</p>
        </div>

        <div className='FeedbackMessageBox__action'>
          <ExitButton id='exit-meet' classModifier='reverse' type='link' />

          <Button id='start-call' onButtonClick={() => action()}>
            {
              loading ? <Spinner color='white' /> : sessionStorage.getItem('connected') ? 'entrar novamente' : 'iniciar consulta'
            }
          </Button>
        </div>
      </div>
    </div>
  )
}