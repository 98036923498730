import React from 'react'
import PropTypes from 'prop-types'

const CameraTip = ({
  fill = '#000',
  height = '30.707',
  width = '41.83'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 41.83 30.707'>
    <g transform='translate(0 -10.7)'>
      <path d='M3,24.474a3.033,3.033,0,0,1-3-3V3A2.978,2.978,0,0,1,3,0H25.243a3.372,3.372,0,0,1,2.139.857A2.969,2.969,0,0,1,28.238,3V6.589l2.225-2.224A2.871,2.871,0,0,1,33.8,3.679,3.092,3.092,0,0,1,35.683,6.5V17.885A3.185,3.185,0,0,1,33.8,20.707a2.9,2.9,0,0,1-3.338-.684L28.238,17.8v3.594a3.051,3.051,0,0,1-3,3.081ZM2.312,3.081V21.564A.733.733,0,0,0,3,22.248H25.243a.736.736,0,0,0,.687-.684v-6.5a1.1,1.1,0,0,1,.769-1.112,1.16,1.16,0,0,1,1.282.258l4.28,4.278a.711.711,0,0,0,.684.17.638.638,0,0,0,.428-.6V6.5a.638.638,0,0,0-.428-.6.672.672,0,0,0-.769.17L27.9,10.269a1.193,1.193,0,0,1-1.282.258,1.223,1.223,0,0,1-.771-1.115V2.91a.736.736,0,0,0-.684-.686H3C2.825,2.312,2.312,2.652,2.312,3.081ZM26.177,21.609c0-.015.006-.03.008-.045v-.085A1.14,1.14,0,0,1,26.177,21.609Zm.008-6.634v.085a.837.837,0,0,1,.006-.1Z' transform='translate(5.647 11.2)' fill={fill} stroke={fill} strokeWidth='1' />
      <g transform='translate(0 24.121)'>
        <path d='M14.718,28.867A8.592,8.592,0,1,0,8.643,43.585h0a8.551,8.551,0,0,0,6.075-2.567,8.473,8.473,0,0,0,0-12.151Z' transform='translate(0 -26.3)' fill={fill} />
        <path d='M0,3.679l1.027-1.2L3.336,4.451,7.188,0l1.2,1.027L3.509,6.76Z' transform='translate(4.45 5.305)' fill='#fff' />
      </g>
    </g>
  </svg>

CameraTip.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default CameraTip
