/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import CallControllers from 'components/CallControllers'
import CallHeader from 'components/CallHeader'
import CallHeaderAlert from 'components/CallHeaderAlert'
import ChatContainer from 'components/ChatContainer'
import CustomIcons, { iconList } from 'components/CustomIcons'
import FeedbackCallMessageBox from 'components/FeedbackCallMessageBox'
import Modal from 'components/Modal'

import { MeetingContext } from 'contexts'
import { colors } from 'utils/stylesConstants'
import { fetchApi } from 'handlers'
import { views } from 'views/viewsCode'
import { errorTypes } from 'views/ErrorView/Errors'

const CallRoom = () => {
  const localAudioRef = useRef()
  const localVideoRef = useRef()
  const remoteAudioRef = useRef()
  const remoteVideoRef = useRef()

  const history = useHistory()
  const { callRoom, localTracks, meeting, updateCallRoom, updateLocalTracks, setView } = useContext(MeetingContext)

  const [participant, setParticipant] = useState(null)
  const [participantDisconnection, setParticipantDisconnection] = useState(null)
  const [participantLeft, setParticipantLeft] = useState(false)

  const [localParticipant, setLocalParticipant] = useState(null)
  const [localDataTrack, setLocalDataTrack] = useState(null)

  const [isChatOpen, setIsChatOpen] = useState(false)
  const [chatMessage, setChatMessage] = useState(null)
  const [toggleDisplay, setToggleDisplay] = useState(true)
  const [canHideElementsMobile, setCanHideElementsMobile] = useState(true)
  const [canHideElementsDesktop, setCanHideElementsDesktop] = useState(false)
  const [mouseMove, setMouseMove] = useState(false)
  const [mouseOver, setMouseOver] = useState(false)
  const [mouseLeft, setMouseLeft] = useState(true)
  const [timeoutHide, setTimeoutHide] = useState(null)

  const [showRemoteVideo, setShowRemoteVideo] = useState(true)
  const [showRemoteAudio, setShowRemoteAudio] = useState(true)
  const [showLocalVideo, setShowLocalVideo] = useState(true)

  const [showModal, setShowModal] = useState(false)
  const [localMessages, setLocalMessages] = useState('Aguardando participante entrar')
  const [startInterval, setStartInterval] = useState(false)

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (callRoom) {
        callRoom.disconnect()
        if (localParticipant && localParticipant.state === 'connected') {
          localTracks.forEach(track => {
            if (track.kind === 'data') return
            if (track.kind === 'video') {
              track.disable()
              track.stop()
              track.detach(localVideoRef.current)
            }
            if (track.kind === 'audio') {
              track.disable()
              track.stop()
              track.detach(localAudioRef.current)
            }
          })
          updateCallRoom(null)
          setParticipant(null)
        }

      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('pagehide', handleBeforeUnload)
    window.addEventListener('onunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('pagehide', handleBeforeUnload)
      window.removeEventListener('onunload', handleBeforeUnload)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const participantConnected = participant => {
      console.log('PARTICIPANT_CONNECTED')
      console.log(participant)
      setParticipantLeft(false)
      setShowRemoteVideo(true)
      setParticipant(participant)
      setParticipantDisconnection(false)
      setCanHideElementsDesktop(true)
      setToggleDisplay(false)
    }

    const participantDisconnected = () => {
      // console.log('PARTICIPANT_DISCONNECTED')
      setParticipantLeft(true)
      setShowRemoteVideo(false)
      setParticipant(null)
      setParticipantDisconnection(true)
      setCanHideElementsDesktop(false)
      setToggleDisplay(true)
    }

    if (callRoom) {
      callRoom.on('participantConnected', participantConnected)
      callRoom.on('participantDisconnected', participantDisconnected)
      callRoom.participants.forEach(participantConnected)
      setLocalParticipant(callRoom.localParticipant)

      callRoom.on('reconnecting', () => {
        fetchApi.sendLogToServer(meeting.schedule.hash, {
          event: "GUEST_RECONNECTING"
        })

        setLocalMessages('Estamos tentando te reconectar. Aguarde.')
      })

      callRoom.on('reconnected', () => setLocalMessages(''))

      callRoom.on('disconnected', (room, error) => {
        if (error) {
          localTracks.forEach(track => {
            if (track.kind === 'data') return
            if (track.kind === 'video') {
              track.stop()
              track.detach(localVideoRef.current)
            }
            if (track.kind === 'audio') {
              track.stop()
              track.detach(localAudioRef.current)
            }

            setLocalParticipant(null)
            updateCallRoom(null)
          });

          (error.code === 53205) && history.replace({
            pathname: 'error',
            state: { errorType: errorTypes.ALREADY_CONNECTED }
          });

          (error.code === 20104) && history.replace({
            pathname: 'error',
            state: { errorType: errorTypes.TIME_UP }
          });

          (error.code === 53000 || error.code === 53204) && history.replace({
            pathname: 'error',
            state: { errorType: errorTypes.CONNECTION }
          })
        }
        Sentry.captureException(error)
        setLocalMessages('Conexão perdida. Você será redirecionado para a sala de espera.')

        const isAppointmentEnded = Boolean(sessionStorage.getItem('appointment-ended'))

        if (isAppointmentEnded) {
          sessionStorage.clear()
          setView(views.nps)
        } else {
          setTimeout(() => {
            updateLocalTracks(null)
            setView(views.waitingRoom)
          }, 3000)
        }
      })
    } else {
      setShowLocalVideo(false)
    }
    // eslint-disable-next-line
  }, [callRoom, history])

  useEffect(() => {
    // console.log('MESSAGE_STATUS_EVENT')
    if (!participant) {
      if (participantDisconnection) setLocalMessages('Participante saiu da sala')
      else setLocalMessages('Aguardando participante entrar')
    } else if (!showRemoteAudio && !showRemoteVideo) {
      setLocalMessages('O participante desligou a câmera e o microfone')
    } else if (!showRemoteAudio) {
      setLocalMessages('O participante desligou o microfone')
      setTimeout(() => setLocalMessages(''), 3000)
    } else if (!showRemoteVideo) {
      setLocalMessages('O participante desligou a câmera')
    } else {
      setLocalMessages('')
    }
  }, [showRemoteAudio, showRemoteVideo, participant, participantDisconnection])

  useEffect(() => {
    // console.log('PARTICIPANT_EVENT')
    const { guest } = meeting

    const trackSubscribed = (track) => {
      if (track.kind === 'data') {
        track.on('message', data => {
          setChatMessage({
            text: data,
            author: guest.name
          })
        })
      } else {
        if (track.kind === "video") {
          if (participantLeft) setParticipantLeft(false)
          const el = remoteVideoRef.current
          el.muted = true
          track.attach(el)
          if (!track.isEnabled) setShowRemoteVideo(false)
          else setShowRemoteVideo(true)
        }

        if (track.kind === "audio") {
          track.attach(remoteAudioRef.current)
          if (!track.isEnabled) setShowRemoteAudio(false)
          else setShowRemoteAudio(true)
        }
      }
    }

    const trackUnsubscribed = track => {
      if (track.kind === "audio") {
        track.detach(remoteAudioRef.current)
        setShowRemoteAudio(false)
      }
      if (track.kind === "video") {
        track.detach(remoteVideoRef.current)
        setShowRemoteVideo(false)
      }
    }

    const trackEnabled = track => {
      if (track.kind === "audio") setShowRemoteAudio(true)
      if (track.kind === "video") setShowRemoteVideo(true)
    }

    const trackDisabled = track => {
      if (track.kind === "audio") setShowRemoteAudio(false)
      else if (track.kind === "video") setShowRemoteVideo(false)
      else return null
    }

    const participantReconnecting = () => {
      setLocalMessages('O participante está tentando reconectar.')
    }

    const participantReconnected = () => {
      setLocalMessages('')
    }

    if (participant) {
      participant.on('trackSubscribed', trackSubscribed)
      participant.on('trackUnsubscribed', trackUnsubscribed)

      participant.on('trackEnabled', trackEnabled)
      participant.on('trackDisabled', trackDisabled)

      participant.on('trackPublished', trackSubscribed)
      participant.on('trackUnpublished', trackUnsubscribed)

      participant.on('networkQualityLevelChanged', () => setParticipantNetwork(participant.networkQualityLevel))

      participant.on('reconnecting', participantReconnecting)
      participant.on('reconnected', participantReconnected)
    }
    // eslint-disable-next-line
  }, [participant, meeting])

  useEffect(() => {
    // console.log('LOCAL_PARTICIPANT_EVENT')

    if (localParticipant) {
      localTracks.forEach(track => {
        if (track.kind === "data") setLocalDataTrack(track)

        if (track.kind === "audio") track.attach(localAudioRef.current)

        if (track.kind === "video") {
          const el = localVideoRef.current
          el.muted = true

          track.attach(el)
          if (!track.isEnabled) setShowLocalVideo(false)
          else setShowLocalVideo(true)
        }
      })

      const trackEnabled = track => { if (track.kind === "video") setShowLocalVideo(true) }
      const trackDisabled = track => { if (track.kind === "video") setShowLocalVideo(false) }

      localParticipant.on('trackEnabled', trackEnabled)
      localParticipant.on('trackDisabled', trackDisabled)

      localParticipant.on('trackPublished', trackEnabled)
      localParticipant.on('trackUnpublished', trackDisabled)
    }
  }, [localParticipant, localTracks])

  const handleDisplayMobile = e => {
    e.preventDefault()
    if (canHideElementsMobile) setToggleDisplay(prevDisplay => !prevDisplay)
  }

  useEffect(() => {
    if (mouseMove && mouseLeft && canHideElementsDesktop && !toggleDisplay && !mouseOver) {
      setToggleDisplay(true)
      setTimeoutHide(setTimeout(() => setToggleDisplay(false), 3000))
    }
  }, [mouseMove, toggleDisplay, mouseOver, canHideElementsDesktop, mouseLeft])

  const handleMouseOver = e => {
    e.preventDefault()
    clearTimeout(timeoutHide)

    if (canHideElementsDesktop) {
      setMouseOver(true)
      setToggleDisplay(true)
      setMouseLeft(false)
    }
  }

  const handleMouseLeave = e => {
    e.preventDefault()

    if (canHideElementsDesktop) {
      setMouseOver(false)
      setToggleDisplay(false)
      setTimeout(() => setMouseLeft(true), 2000)
    }
  }

  const handleMouseMove = e => {
    e.preventDefault()
    setMouseMove(true)

    let timeoutEvent = null
    clearTimeout(timeoutEvent)
    timeoutEvent = setTimeout(() => setMouseMove(false), 50)
  }

  const handleChatBox = () => setIsChatOpen(isChatOpen ? false : true)

  const onSendMessage = useCallback(message => {
    // console.log('CHAT_EVENT')
    const { user } = meeting

    setChatMessage({
      text: message,
      author: user ? user.name : "você"
    })

    if (localDataTrack) {
      localDataTrack.send(message)
    }
  }, [localDataTrack, meeting])

  const handleLeaveCall = useCallback(() => {
    // console.log('LEAVE_CALL')
    setShowModal(showModal ? false : true)
  }, [showModal])

  const handleStartInterval = () => {
    setCanHideElementsDesktop(false)
    setCanHideElementsMobile(false)
    setToggleDisplay(true)
    if (meeting.schedule.interval === 0) handleExit()
    else setStartInterval(true)
  }

  const handleExit = () => {
    if (callRoom) {
      sessionStorage.setItem('appointment-ended', true)
      callRoom.disconnect()
      if (localParticipant && localParticipant.state === 'connected') {
        localTracks.forEach(track => {
          if (track.kind === 'data') return
          if (track.kind === 'video') {
            track.disable()
            track.stop()
            track.detach(localVideoRef.current)
          }
          if (track.kind === 'audio') {
            track.disable()
            track.stop()
            track.detach(localAudioRef.current)
          }
        })
        updateCallRoom(null)
        setParticipant(null)
      }
    }
    sessionStorage.clear()
  }

  return (
    <div className='CallRoom'>
      {
        showModal && <Modal handleClick={handleLeaveCall} handleExit={handleExit} />
      }

      <ChatContainer
        isChatOpen={isChatOpen}
        chatMessage={chatMessage}
        handleToggleChat={handleChatBox}
        handleSendMessage={m => onSendMessage(m)}
      />

      <div className='CallRoom__components'>
        <div className='CallRoom__components__header'>
          <div className='CallRoom__components__header--left'
            onMouseOver={e => handleMouseOver(e)}
            onMouseLeave={e => handleMouseLeave(e)}>
            <CallHeader startInterval={() => handleStartInterval()} showElement={toggleDisplay} />
            {
              startInterval &&
              <CallHeaderAlert onExit={() => handleExit()} interval={meeting.sechedule ? meeting.schedule.interval : 10} />
            }
          </div>

          <div className='CallRoom__components__header--right'>
            {
              !showRemoteAudio &&
              <div className='Participant__mic-off'>
                <CustomIcons icon={iconList.MicrophoneOff} fill={colors.WHITE} width='14.97px' height='15.8px' />
              </div>
            }
          </div>
        </div>

        <div className='CallRoom__components__main'
          onTouchEnd={e => handleDisplayMobile(e)}
          onMouseMove={e => handleMouseMove(e)}>
          <FeedbackCallMessageBox>{localMessages}</FeedbackCallMessageBox>

          <div className='LocalVideoContainer'>
            {
              !showLocalVideo ? <div className='LocalVideoContainer__icon'>
                <CustomIcons icon={iconList.CameraOff} fill={colors.WHITE} />
              </div> : null
            }

            <video ref={localVideoRef} />
            <audio ref={localAudioRef} />
          </div>
        </div>

        <div className='CallRoom__components__footer'
          onMouseOver={e => handleMouseOver(e)}
          onMouseLeave={e => handleMouseLeave(e)}>
          <CallControllers toggleChatBox={handleChatBox} toggleModal={handleLeaveCall} showElement={toggleDisplay} />
        </div>
      </div>

      <div className='MainVideoContainer'>
        {!participantLeft && <video ref={remoteVideoRef} />}
        <audio ref={remoteAudioRef} />
      </div>
    </div>
  )
}

export default CallRoom
