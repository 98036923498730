import React from 'react'
import PropTypes from 'prop-types'

const CameraOn = ({
  fill = '#000',
  height = '24',
  width = '24'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
    <path d='M0,0H24V24H0Z' fill='none' />
    <path d='M17,10.5V7a1,1,0,0,0-1-1H4A1,1,0,0,0,3,7V17a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V13.5l4,4V6.5Z' fill={fill} />
  </svg>

CameraOn.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default CameraOn
