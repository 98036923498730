/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react'

import CustomIcons, { iconList } from 'components/CustomIcons'
import { dateTimeHandler } from 'handlers'
import { colors } from 'utils/stylesConstants'

const ChatContainer = ({ isChatOpen, handleToggleChat, chatMessage, handleSendMessage }) => {
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [btnDisabled, setBtnDisabled] = useState(true)

  useEffect(() => {
    if (chatMessage && chatMessage.text.length > 0) {
      setMessages(prevMessages => ([...prevMessages, chatMessage]))

      if (!isChatOpen) handleToggleChat()
    }

    const chatBox = document.getElementById("chat-box")
    if (chatBox)
      chatBox.scrollTop = chatBox.scrollHeight
    // eslint-disable-next-line
  }, [chatMessage])


  const handleInput = e => {
    setBtnDisabled(false)
    setInputValue(e.target.value)
    if (e.target.value === '') setBtnDisabled(true)
  }

  const submitMessage = () => {
    handleSendMessage(inputValue)
    setInputValue('')
    setBtnDisabled(true)
  }

  return (
    <Fragment>
      {
        isChatOpen &&
        <div className='ChatContainer'>
          <div className='ChatContainer__header'>
            <p>Chat</p>
            <div className='ChatContainer__icon' onClick={handleToggleChat}>
              <CustomIcons icon={iconList.Close} fill={colors.GREY_05} />
            </div>
          </div>

          {
            messages.length > 0 ?
              <div className="ChatContainer__content" id="chat-box">
                {
                  messages.length > 0 && messages.map((message, index) => {
                    return (
                      <div className="ChatContainer__content__localMsg" key={index}>
                        <p>{message.text}</p>
                        <p className="ChatContainer__content__localMsg--info">{message.author} | {dateTimeHandler.getTime()}</p>
                      </div>
                    )
                  })
                }
              </div> :
              <div className='ChatContainer__feedback-message'>
                <p>{`Escreva uma mensagem\nno campo abaixo`}</p>
              </div>
          }

          <div className='ChatContainer__input'>
            <input
              autoComplete='off'
              id='input_message'
              placeholder={'Digite uma mensagem'}
              onChange={handleInput}
              onFocus={() => setBtnDisabled(false)}
              type='text'
              value={inputValue}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  submitMessage(inputValue)
                  setInputValue('')
                }
              }}
            />

            <button disabled={btnDisabled} type='button' id='btn-chat-submit' onClick={submitMessage}>
              <CustomIcons icon={iconList.Send} fill={btnDisabled ? colors.GREY_06 : colors.WHITE} />
            </button>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default ChatContainer
