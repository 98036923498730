/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react'

import CustomIcons, { iconList } from 'components/CustomIcons'
import { colors } from 'utils/stylesConstants'

const CallEndButton = ({ handleLeaveCall, hasAlt = false }) => {
  const [showAltBox, setShowAltBox] = useState(false)

  const iconProps = {
    icon: iconList.CallEnd,
    fill: colors.WHITE
  }

  return (
    <Fragment>
      {
        hasAlt && showAltBox &&
        <div className='AltBox--endCall'>
          <p>Sair</p>
        </div>
      }

      <div className={'CallEndButton'}
        onClick={handleLeaveCall}
        onMouseOver={() => setShowAltBox(true)}
        onMouseOutCapture={() => setShowAltBox(false)}>
        <CustomIcons {...iconProps} />
      </div>
    </Fragment>
  )
}

export default CallEndButton
