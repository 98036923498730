import React from 'react'
import PropTypes from 'prop-types'

const Chat = ({
  fill = '#000',
  height = '24',
  width = '24'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 24 24'>
    <path d='M20,2H4A2,2,0,0,0,2.01,4L2,22l4-4H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2ZM6,9H18v2H6Zm8,5H6V12h8Zm4-6H6V6H18Z' fill={fill} />
    <path d='M0,0H24V24H0Z' fill='none' />
  </svg>

Chat.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default Chat
