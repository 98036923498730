import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ErrorView from 'views/ErrorView'
import Homepage from 'views/Homepage'

const App = () => {
  return (
    <div className='App'>
      <Switch>
        <Route path='/error'><ErrorView /></Route>
        <Route path='/:hash'><Homepage /></Route>
        <Route path='/'><Homepage /></Route>
      </Switch>
    </div>
  )
}

export default App