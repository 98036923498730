import React from 'react'
import PropTypes from 'prop-types'

const Spinner = ({ color }) =>
  <div className="Spinner">
    <div className={`Spinner__icon${color ? '--' + color : ''}`} />
  </div>

Spinner.propTypes = {
  color: PropTypes.oneOf([
    "white",
    "black"
  ])
}

export default Spinner