/* eslint-disable no-undef */
import axios from 'axios'

import { apiUrl } from './apiSettings'
import momentTz from 'moment-timezone'

/** 
 * Axios Instance 
**/
const instance = axios.create({ baseURL: `${apiUrl}` })
/* Global axios interceptor for requests */
instance.interceptors.request.use(requestConfig => {
  requestConfig.headers = {
    ...instance.defaults.headers,
    "Time-Zone": momentTz.tz.guess(),
  }

  let token = process.env.REACT_APP_IQUILIBRIO_API_TOKEN

  if (localStorage.getItem('iquiAccessToken')) {
    token = localStorage.getItem('iquiAccessToken');
  }

  requestConfig.headers["Authorization"] = `Bearer ${token}`
  return requestConfig
}, err => { return Promise.reject(err) })

/* Global axios interceptor for responses */
instance.interceptors.response.use(response => {
  return response
}, err => {
  return Promise.reject(err.response)
})

export default instance