export const apiUrl = process.env.REACT_APP_API_URL
export const authApiUrl = process.env.REACT_APP_API_AUTH_URL
export const mainSite = process.env.REACT_APP_CORE_URL

export const endpoints = {
  AUTH: (roomHash) => `${apiUrl}/${roomHash}/`,
  CORE: `${mainSite}/dashboard`,
  JOIN: (roomHash) => `${apiUrl}/${roomHash}/access-tokens/`,
  LOG: (roomHash) => `${apiUrl}/${roomHash}/logs/`,
  LOGIN: `${mainSite}`,
  FACE_ID: `${authApiUrl}/`,
  IQUILIBRIO_URL: `${process.env.REACT_APP_IQUILIBRIO_URL}`,
  IQUILIBRIO_API_URL: `${process.env.REACT_APP_IQUILIBRIO_API_URL}`,
}