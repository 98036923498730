import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'

export const ExitButton = ({ id = 'exit', text = 'sair', ...props }) => {
  const handleBtnClick = () => {
    sessionStorage.clear()
    window.history.back()
  }

  return (
    <Button id={id} onButtonClick={handleBtnClick} {...props}>
      {text}
    </Button>
  );
}

ExitButton.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
}
