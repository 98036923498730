/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import Timer from 'react-compound-timer'

import CustomIcons, { iconList } from 'components/CustomIcons'

import { colors } from 'utils/stylesConstants'

const CallHeaderAlert = ({ onExit, interval }) => {
  const callInterval = interval ? (interval * 60000) : 480000

  return (
    <div className='CallHeaderAlert'>
      <div className='CallHeaderAlert__timerbox'>
        <CustomIcons icon={iconList.Clock} fill={colors.WHITE} />

        <div className='CallHeaderAlert__timerbox__timer'>
          {
            callInterval > 0 ?
              <Timer
                direction="forward"
                initialTime={0}
                startImmediately={true}
                formatValue={value => `${(value < 10 ? `0${value}` : value)}`}>
                {({ setCheckpoints, stop }) => {
                  setCheckpoints([
                    {
                      time: callInterval,
                      callback: () => {
                        stop()
                        onExit()
                      },
                    },
                  ])
                  return (
                    <Fragment>
                      <span><Timer.Minutes />:<Timer.Seconds /></span>
                    </Fragment>
                  )
                }}
              </Timer> : onExit()
          }
        </div>
      </div>

      <div className='CallHeaderAlert__text'>
        <p>Procure encerrar a consulta</p>
      </div>
    </div>
  )
}

export default CallHeaderAlert
