/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Video, { isSupported, LocalDataTrack } from 'twilio-video'
import * as Sentry from '@sentry/react'

import AudioButton from 'components/AudioButton'
import AudioLevel from 'components/AudioLevel'
import TipsMessageBox from 'components/TipsMessageBox'
import VideoButton from 'components/VideoButton'
import { FeedbackMessageManager, messageCode, messageComponents } from 'components/FeedbackMessageManager'
import { ReadyToCallMessage } from 'components/FeedbackMessageManager/messageComponents/ReadyToCallMessage'

import { views } from 'views/viewsCode'
import { MeetingContext } from 'contexts'

import Logo from '../../static/imgs/logo.png'

import axios from 'settings/axios'
import { colors } from 'utils/stylesConstants'
import { dateTimeHandler } from 'handlers'
import { endpoints } from 'settings'
import { errorTypes } from 'views/ErrorView/Errors'

const WaitingRoom = () => {
  const history = useHistory()

  const videoRef = useRef()
  const audioRef = useRef()

  const { localTracks, meeting, updateCallRoom, updateLocalTracks, updateMeeting, setView,
    hash, token } = useContext(MeetingContext)

  const [audioEnabled, setAudioEnabled] = useState(true)
  const [videoEnabled, setVideoEnabled] = useState(true)

  const [loading, setLoading] = useState(false)
  const [localMessages, setLocalMessages] = useState({ message: 'Conectando câmera e microfone', color: colors.WHITE })
  const [roomState, setRoomState] = useState(messageCode.PREPARING_ROOM)
  const [permission, setPermission] = useState(false)

  useEffect(() => {
    if (!hash || hash === "null") {
      setRoomState(messageCode.ROOM_NOT_FOUND)
      getLocalTracks(hash)
    } else {
      handleAuthentication(hash)
    }
  }, [hash])

  useEffect(() => {
    if (localTracks) {
      setLocalMessages({ message: '' })

      if (!videoEnabled) setLocalMessages({
        message: 'Câmera desligada',
        color: colors.WHITE
      })

      if (!videoEnabled && !audioEnabled)
        setLocalMessages({
          message: 'Câmera e microfone desligados',
          color: colors.WHITE
        })
    } else {
      setLocalMessages({
        message: 'Conectando câmera e microfone',
        color: colors.GREY_07
      })
    }
  }, [audioEnabled, videoEnabled, localTracks])

  const handleAuthentication = useCallback(async (hash) => {
    try {
      localStorage.setItem('iquiAccessToken', token)

      // Validate room
      const { data } = await axios.get(`${endpoints.IQUILIBRIO_API_URL}/v1/appointments/${hash}`)
      
      const user = await axios.get(`${endpoints.IQUILIBRIO_API_URL}/v1/current-user`)
      let guest = {}

      if (user.data.type === 'customer') {
        guest = await axios.get(`${endpoints.IQUILIBRIO_API_URL}/v1/sellers/${data.seller_id}`)
      } else {
        guest = await axios.get(`${endpoints.IQUILIBRIO_API_URL}/v1/customers/${data.customer_id}`)
      }

      const mockedTimestamp = new Date().getTime()

      /** Salvar a diferença de horário do servidor com  o local 
      para a sincronização do timer na consulta */
      const appointmentDurationInHours = 1;
      const startDate = new Date(data.date);
      const endDate = new Date(data.date).setHours(startDate.getHours() + appointmentDurationInHours)
      const serverTime = dateTimeHandler.getDateFromTimestamp(mockedTimestamp)
      const serverDuration = dateTimeHandler.getDurationInMs(serverTime, endDate) || 0
      const localDuration = dateTimeHandler.getDurationInMs(null, endDate) || 0
      const diffDurationToNormalize = serverDuration - localDuration

      updateMeeting({
        user: user.data,
        guest: guest.data,
        schedule: {
          id: data.id,
          hash,
          startDate,
          endDate,
          diffDuration: diffDurationToNormalize,
          interval: '1',
        }
      })

      setPermission(true)

      // Sentry.configureScope(scope => scope.setUser({ email: user.email }))
      // fetchApi.sendLogToServer(schedule.virtual_room_hash, {
      //   event: "GUEST_CONNECTED",
      //   is_supported: isSupported
      // })
    } catch (err) {
      setPermission(false)
      // Sentry.captureException(err)
      if (err && err.status === 403) {
        setRoomState(messageCode.ERROR_AUTH)
      } else if (err && err.status === 404) {
        setRoomState(messageCode.ROOM_NOT_FOUND)
      } else if (err && err.status === 410) {
        history.replace({
          pathname: 'error',
          state: { errorType: errorTypes.TIME_UP }
        })
      } else {
        setRoomState(messageCode.ERROR_AUTH)
      }
    } finally {
      if (!isSupported) history.replace({
        pathname: 'error',
        state: { errorType: errorTypes.BROWSER }
      })
      else getLocalTracks()
    }
  }, [])

  const getLocalTracks = () => {
    if (!localTracks) {
      const dataTrack = new LocalDataTrack()

      Video.createLocalTracks().then(tracks => {
        const localTracks = [...tracks, dataTrack]

        tracks.forEach(localTracks => {
          if (localTracks.kind === "audio") localTracks.attach(audioRef.current)
          if (localTracks.kind === "video") localTracks.attach(videoRef.current)
        })

        updateLocalTracks(localTracks)
      }).catch(err => {
        Sentry.captureException(err)

        setLocalMessages({
          message: 'Você precisa autorizar o uso da câmera e microfone.',
          color: colors.GREY_07
        })
      })
    }
  }

  const getAccessToken = async () => {
    setLoading(true)

    const { schedule } = meeting

    if (schedule) {
      try {
        const { data } = await axios.post(`${endpoints.IQUILIBRIO_API_URL}/v1/appointments/${hash}/video-rooms`);
        if (data.token) connectToTwillio(data.token, data.room.name)
        else throw new Error("accessToken inválido")
      } catch (err) {
        Sentry.captureException((err))

        setLoading(false)
        setPermission(false)
        setRoomState(messageCode.ERROR_AUTH)
      }
    } else {
      console.log('not schedule');
      setLoading(false)
      setPermission(false)
      setRoomState(messageCode.ERROR_AUTH)
    }
  }

  const connectToTwillio = async (accessToken, name) => {
    if (!localTracks) {
      setRoomState(messageCode.ERROR_AUTH)
      setPermission(false)
    } else {
      let connectOptions = {
        audio: true,
        video: { height: 720, frameRate: 24, width: 1440 },
        tracks: localTracks,
        bandwidthProfile: {
          video: {
            mode: 'grid',
            maxTracks: 6,
            maxSubscriptionBitrate: 2500000,
            renderDimensions: {
              high: { height: 1080, width: 1920 },
              standard: { height: 720, width: 1280 },
              low: { height: 176, width: 144 }
            }
          }
        },
        maxAudioBitrate: 16000,
        networkQuality: { local: 1, remote: 1 },
        name,
        // logLevel: 'debug'
      }

      await Video.connect(accessToken, connectOptions)
        .then(room => {
          sessionStorage.setItem('connected', true)
          updateCallRoom(room)
        }).then(() => {
          const prevVideo = localTracks.find(track => track.kind === "video")
          prevVideo.detach(videoRef.current)
        }).then(() => {
          setView(views.callRoom)
        }).catch(err => {
          console.log('err', err);
          Sentry.captureException(err)
          history.replace('error')
          setView(views.waitingRoom)
        })
    }
  }

  return (
    <div className='WaitingRoom'>
      <div className='LogoContainer'>
        <img src={Logo} alt="Anyma's logo" className='Logo' />
      </div>
      <div className='WaitingRoom__main'>
        <div className={`VideoContainerBox${!localTracks ? '--disabled' : ''}`}>
          <div className='FeedbackMessage'>
            <p style={{ color: `${localMessages.color}` }}>{localMessages.message}</p>
          </div>

          <div className='LocalVideo'>
            <video ref={videoRef} />
            <audio ref={audioRef} />
          </div>

          <div className='Controls'>
            <div className='Controls__buttonContainer'>
              {localTracks && <AudioButton isWaittingRoom={true} hideTrack={(isEnabled) => setAudioEnabled(isEnabled)} />}
            </div>
            <AudioLevel isEnabled={audioEnabled} />


            <div className='Controls__buttonContainer'>
              {localTracks && <VideoButton isWaittingRoom={true} hideTrack={(isEnabled) => setVideoEnabled(isEnabled)} />}
            </div>
          </div>
        </div>

        {
          permission && localTracks ? <ReadyToCallMessage action={getAccessToken} loading={loading} /> :
            <FeedbackMessageManager message={messageComponents[roomState]} />
        }
      </div>

      <TipsMessageBox />
    </div>
  )
}

export default WaitingRoom
