/* eslint-disable react/prop-types */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Timer from 'react-compound-timer'

import { MeetingContext } from 'contexts'
import { dateTimeHandler } from 'handlers'

const CallHeader = ({ startInterval, showElement }) => {
  const { meeting } = useContext(MeetingContext)

  const [startTimer, setStartTimer] = useState(0)
  const [timeUp, setTimeUp] = useState(false)
  const [guestName, setGuestName] = useState('Participante')

  useEffect(() => {
    // console.log('START TIMER')
    const { schedule, guest } = meeting
    if (guest) setGuestName(guest.name)

    if (schedule) {
      const remainingDuration = dateTimeHandler.getDurationInMs(null, schedule.endDate)
      setStartTimer(remainingDuration)
    }
  }, [meeting])

  return (
    <div className={`CallHeader${showElement ? "" : "--hide"}`}>
      <div className='CallHeader__guest-name'>
        <span>Consulta com</span>
        <p>{guestName}</p>
      </div>

      <div className='CallHeader__timerbox'>
        {
          timeUp &&
          <div className='CallHeader__timerbox__timeUp'>
            <p>Tempo acabou</p>
          </div>
        }

        <div className='CallHeader__timerbox__timer'>
          {
            startTimer !== 0 && !timeUp &&
            <Timer
              direction="backward"
              formatValue={value => `${(value < 10 ? `0${value}` : value)}`}
              initialTime={startTimer}>
              {({ setCheckpoints, stop }) => {
                setCheckpoints([
                  {
                    time: 0,
                    callback: () => {
                      stop()
                      setTimeUp(true)
                      startInterval()
                      sessionStorage.setItem('appointment-ended', true)
                    },
                  },
                ])
                return (
                  <Fragment>
                    <p className="CallHeader__timerbox__timer__left">Restam</p> <span className="CallHeader__timerbox__timer__seconds"><Timer.Minutes />:<Timer.Seconds /></span>
                  </Fragment>
                )
              }}
            </Timer>
          }
        </div>
      </div>
    </div>
  )
}

export default CallHeader
