import React from 'react'

import ExitButton from 'components/ExitButton'

export const TimesUpError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>O horário desta consulta já passou.</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Volte para a sua página pessoal.</p>
      </div>

      <div className='ConnectionError__button'>
        <ExitButton text='voltar' />
      </div>
    </div>
  )
}