/* eslint-disable indent */
import { detect } from 'detect-browser'

export const browserDetectionHandler = () => {
  const browser = detect()
  let browsersSupported = []

  if (!browser) return null

  const systemOs = browser.os.split(' ')[0]

  switch (systemOs) {
    case 'Android':
    case 'Linux':
      browsersSupported = ['chrome', 'firefox'];
      break;
    case 'iOS':
      browsersSupported = ['safari'];
      break;
    case 'macOS':
      browsersSupported = ['chrome', 'firefox', 'safari', 'edge-chromium'];
      break;
    case 'Windows':
      browsersSupported = ['chrome', 'firefox', 'edge-chromium'];
      break;
    default:
      browsersSupported = [];
  }

  return {
    browser: { ...browser },
    browsersSupported
  }
}