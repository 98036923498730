import React, { useEffect, useState } from 'react'

import Button from 'components/Button'

import { browserDetectionHandler } from 'handlers'

export const BrowserError = () => {
  const getBrowser = browserDetectionHandler()

  const [chrome, setChrome] = useState(null)
  const [firefox, setFirefox] = useState(null)
  const [edge, setEdge] = useState(null)
  const [safari, setSafari] = useState(null)

  const [needUpdate, setNeedUpdate] = useState(null)

  useEffect(() => {
    getBrowser.browsersSupported.map(item => {
      if (item === 'chrome') setChrome(true)
      if (item === 'firefox') setFirefox(true)
      if (item === 'edge-chromium') setEdge(true)
      if (item === 'safari') setSafari(true)
      return item
    })
  }, [getBrowser])

  useEffect(() => {
    const { browser, browsersSupported } = getBrowser
    const isEqualBrowser = browsersSupported.find(item => item === browser.name)
    if (isEqualBrowser) setNeedUpdate(true)

    return () => {
      setNeedUpdate(null)
    }
  }, [getBrowser])


  return (
    <div className='BrowserError'>
      <div className='BrowserError__title'>
        <p>
          {
            needUpdate ?
              `A consulta não funciona\nnesta versão do navegador\n(${getBrowser.browser.version})` :
              'A consulta não funciona neste navegador'
          }
        </p>
      </div>

      <div className='BrowserError__message'>
        <p>
          {
            needUpdate ?
              'Para conseguir entrar na consulta, atualize para a versão mais recente ou utilize outro navegador.' :
              'Para conseguir entrar na consulta, utilize um dos navegadore abaixo.'
          }
        </p>
      </div>

      <div className='BrowserError__buttons'>
        {
          chrome &&
          <Button classModifier='small' id='download-chrome'
            onButtonClick={() => window.location.assign('https://www.google.com/chrome')}>
            baixar chrome
          </Button>
        }
        {
          firefox &&
          <Button classModifier='small' id='download-firefox'
            onButtonClick={() => window.location.assign('https://www.mozilla.org/firefox/new')}>
            baixar firefox
          </Button>
        }
        {
          edge &&
          <Button classModifier='small' id='download-edge'
            onButtonClick={() => window.location.assign('https://www.microsoft.com/edge')}>
            baixar edge
          </Button>
        }
        {
          safari &&
          <Button classModifier='small' id='download-safari'
            onButtonClick={() => window.location.assign('https://support.apple.com/downloads/safari')}>
            baixar safari
          </Button>
        }
      </div>
    </div>
  )
}