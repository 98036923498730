import React from 'react'
import PropTypes from 'prop-types'

const Close = ({
  fill = '#000',
  height = '24',
  width = '24'
}) =>
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" fill={fill} />
  </svg>

Close.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default Close
