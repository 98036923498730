import React from 'react'

import ExitButton from 'components/ExitButton'
import CustomIcons, { iconList } from 'components/CustomIcons'

import { colors } from 'utils/stylesConstants'

export const UnauthorizedErrorMessage = () => {
  return (
    <div className="FeedbackMessageBox">
      <div className="FeedbackMessageBox__icon">
        <CustomIcons icon={iconList.Alert} fill={colors.RED} />
      </div>

      <div className="FeedbackMessageBox__title">
        <p>Ops, houve algum problema</p>
      </div>

      <div className='FeedbackMessageBox__message'>
        <p>Saia e tente entrar novamente.</p>
      </div>

      <div className='FeedbackMessageBox__action'>
        <ExitButton classModifier='reverse' type='link' />
      </div>
    </div>
  )
}