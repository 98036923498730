import React from 'react'
import PropTypes from 'prop-types'

const Alert = ({
  fill = '#000',
  height = '50',
  width = '50'
}) =>
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 50 50'>
    <g transform='translate(-153 -433)'>
      <g transform='translate(153 433)' fill='none' stroke={fill} strokeWidth="3">
        <circle cx='25' cy='25' r='25' stroke='none' />
        <circle cx='25' cy='25' r='23.5' fill='none' />
      </g>
      <g transform='translate(175.144 443.777)'>
        <path d='M42.59,62.35a2.805,2.805,0,1,1,2.8-2.8A2.8,2.8,0,0,1,42.59,62.35Z' transform='translate(-39.369 -33.77)' fill={fill} />
        <path d='M42.389,22.671c-5.027,0-3.5,9.378,0,20.386C45.884,32.049,47.416,22.671,42.389,22.671Z' transform='translate(-39.168 -22.671)' fill={fill} />
      </g>
    </g>
  </svg>

Alert.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string
}

export default Alert
