/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useContext, useEffect, useRef, useState } from 'react'
import { interval } from 'd3-timer'

import { MeetingContext } from 'contexts'

let clipId = 0
const getUniqueClipId = () => clipId++

const AudioContext = window.AudioContext || window.webkitAudioContext
let audioContext

export function initializeAnalyser(audioTrack) {
  audioContext = audioContext || new AudioContext()
  const stream = new MediaStream([audioTrack.mediaStreamTrack.clone()])
  const audioSource = audioContext.createMediaStreamSource(stream)

  const analyser = audioContext.createAnalyser()
  analyser.smoothingTimeConstant = 0.4
  analyser.fftSize = 512

  audioSource.connect(analyser)
  return analyser
}

function AudioLevel({ isEnabled }) {
  const { localTracks } = useContext(MeetingContext)
  const [audioTrack, setAudioTrack] = useState(null)

  const ref = useRef(null)

  useEffect(() => {
    if (localTracks) {
      const track = localTracks.find(track => track.kind === 'audio')
      setAudioTrack(track)
    }
  }, [localTracks])

  useEffect(() => {
    const SVGClipElement = ref.current

    if (audioTrack && isEnabled && SVGClipElement) {
      let analyser = initializeAnalyser(audioTrack)

      const reinitializeAnalyser = () => {
        analyser = initializeAnalyser(audioTrack)
      }

      // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
      // where the analysers stop functioning when the user switches to a new tab
      // and switches back to the app.
      window.addEventListener('focus', reinitializeAnalyser)

      const sampleArray = new Uint8Array(analyser.frequencyBinCount)

      const timer = interval(() => {
        analyser.getByteFrequencyData(sampleArray)
        let values = 0

        const length = sampleArray.length
        for (let i = 0; i < length; i++) {
          values += sampleArray[i]
        }
        const volume = Math.min(21, Math.max(0, Math.log10(values / length / 3) * 14));

        SVGClipElement.setAttribute('y', String(21 - volume))
      }, 50)

      return () => {
        SVGClipElement.setAttribute('y', '30')
        timer.stop()
        window.removeEventListener('focus', reinitializeAnalyser)
      };
    }
  }, [audioTrack, isEnabled])

  // Each instance of this component will need a unique HTML ID
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`

  return (
    <svg focusable="false" aria-hidden="true" width="8" height="31" viewBox="0 0 8 31">
      <defs>
        <clipPath id={clipPathId}>
          <rect ref={ref} x="0" y="30" width="6" height="30" rx="3" />
        </clipPath>
      </defs>

      <g transform="translate(-165 -198)">
        <rect width="8" height="31" rx="4" transform="translate(165 198)" fill={isEnabled ? "#fff" : "#c1c1c1"} />
        <path d="M0,0H6A0,0,0,0,1,6,0V20a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z" transform="translate(166 206)" fill={isEnabled ? "#4ac326" : "#c1c1c1"} clipPath={isEnabled ? `url(#${clipPathId})` : null} />
        <g transform="translate(165 198)" fill="none" stroke="#fff" strokeWidth="2px">
          <rect width="8" height="31" rx="4" stroke="none" />
          <rect x="1" y="1" width="6" height="29" rx="3" fill="none" />
        </g>
      </g>
    </svg>
  )
}

export default React.memo(AudioLevel)
