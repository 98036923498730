import React from 'react'

import ExitButton from 'components/ExitButton'

export const UnexpectedError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Ops, houve algum problema</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Verifique sua conexão e tente entrar novamente na sua consulta.</p>
      </div>

      <div className='ConnectionError__button'>
        <ExitButton text='entrar novamente' />
      </div>
    </div>
  )
}