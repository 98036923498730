/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'

const FeedbackCallMessageBox = ({ children }) => {
  return (
    <Fragment>
      {
        children &&
        <div className='FeedbackCallMessageBox'>
          <p>{children}</p>
        </div>
      }
    </Fragment>
  )
}

export default FeedbackCallMessageBox
