import { useEffect } from 'react'

import delighted from 'lib'

export const Delighted = ({ name, email }) => {
  useEffect(() => {
    let cleanSideEffects = true
    let script

    if (cleanSideEffects) {
      script = document.createElement('script')
      script.innerHTML = delighted

      document.body.appendChild(script)
      window.delighted.survey({
        email,
        name,
        properties: {
          orderId: "123ABC",
          shippingMethod: "Ground"
        },
      })
    }

    return () => {
      document.body.removeChild(script)
      cleanSideEffects = false
    }
  }, [])

  return null
}
