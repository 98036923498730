/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import Button from 'components/Button'
import Spinner from 'components/Spinner'

const Modal = ({ handleClick, handleExit }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleExitBtn = () => {
    setIsLoading(true)
    handleExit()
  }

  return (
    <div className='Modal'>
      <div className='Modal__backdrop'></div>

      <div className='Modal__container'>
        <div className='Modal__container__content'>
          <p>Tem certeza que deseja encerrar a consulta?</p>

          <div className='Modal__container__content__buttons'>
            <Button id='leave-call' onButtonClick={() => handleExitBtn()}>
              {
                isLoading ? <Spinner color="white" /> : "Sim"
              }
            </Button>
            <Button id='close-modal' onButtonClick={() => handleClick()}>
              Não
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal