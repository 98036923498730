export const primaryColor = '#391DDD'
export const secondaryColor = '#FF909E'
export const darkerPrimaryColor = '#2f17b5'
export const darkerSecondaryColor = '#FF7C8C'

export const colors = {
  PRIMARY: '#0C43E9',
  PRIMARY_LIGHT: '#E0F2F4',
  PRIMARY_DARK: '#055553',
  SECONDARY: '#22cbd3',
  SECONDARY_LIGHT: '#d3f5f6',
  SECONDARY_DARK: '#1a989e',
  BLACK: '#222222',
  WHITE: '#fff',
  GREEN: '#4ac326',
  RED: '#ff1744',
  ORANGE: '#f9a435',
  YELLOW: '#ffffb5',
  GREY_01: '#f5f5f5',
  GREY_02: '#E8E8EA',
  GREY_03: '#B9BBC0',
  GREY_04: '#A6A6A8',
  GREY_05: '#68696B',
  GREY_06: '#4D4E51',
  GREY_07: '#4b4b4b'
}
