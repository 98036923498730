import React from 'react'

import ExitButton from 'components/ExitButton'

export const ConnectionError = () => {
  return (
    <div className='ConnectionError'>
      <div className='ConnectionError__title'>
        <p>Nenhuma conexão de rede</p>
      </div>

      <div className='ConnectionError__message'>
        <p>Você não está conectado à internet.
        Verifique sua conexão e tente entrar novamente.</p>
      </div>

      <div className='ConnectionError__button'>
        <ExitButton text='entrar novamente' />
      </div>
    </div>
  )
}