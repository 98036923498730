/* eslint-disable react/prop-types */
import React, { useCallback, useContext, useEffect, useState, Fragment } from 'react'

import CustomIcons, { iconList } from 'components/CustomIcons'
import { MeetingContext } from 'contexts'
import { colors } from 'utils/stylesConstants'

const AudioButton = ({ hideTrack = null, isWaittingRoom, hasAlt = false }) => {
  const { localTracks } = useContext(MeetingContext)

  const [isEnabled, setIsEnabled] = useState(true)
  const [showAltBox, setShowAltBox] = useState(false)

  const iconProps = {
    icon: isEnabled ? iconList.MicrophoneOn : iconList.MicrophoneOff,
    fill: isEnabled ? isWaittingRoom ? colors.PRIMARY : colors.PRIMARY :
      isWaittingRoom ? colors.WHITE : colors.RED
  }

  useEffect(() => {
    if (localTracks) {
      const track = localTracks.find(track => track.kind === 'audio')
      setIsEnabled(track.isEnabled)
    }
  }, [localTracks])

  const handleTrack = useCallback(() => {
    if (localTracks) {
      let track = localTracks.find(track => track.kind === 'audio')
      if (track.isEnabled) {
        track.disable()
        if (hideTrack) hideTrack(false)
      } else {
        track.enable()
        if (hideTrack) hideTrack(true)
      }
      setIsEnabled(track.isEnabled)
    }
  }, [localTracks, hideTrack])

  return (
    <Fragment>
      {
        hasAlt && showAltBox &&
        <div className='AltBox--audio'>
          <p>{isEnabled ? "Desligar microfone" : "Ligar microfone"}</p>
        </div>
      }
      <div
        className={`AudioButton${!isEnabled ? '--off' : '--on'}`}
        onClick={handleTrack}
        onMouseOver={() => setShowAltBox(true)}
        onMouseOutCapture={() => setShowAltBox(false)}>
        <CustomIcons {...iconProps} />
      </div>
    </Fragment>
  )
}

export default AudioButton
