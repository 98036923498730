/* eslint-disable react/prop-types */
import React from 'react'

import AudioButton from 'components/AudioButton'
import CallEndButton from 'components/CallEndButton'
import ChatButton from 'components/ChatButton'
import VideoButton from 'components/VideoButton'

const CallControllers = ({ toggleChatBox, toggleModal, showElement }) => {
  return (
    <div className={`CallControllers${showElement ? "" : "--hide"}`}>
      <div className='CallControllers__buttons'>
        <AudioButton hasAlt />
        <VideoButton hasAlt />
        <ChatButton hasAlt toggleChatBox={toggleChatBox} />
        <CallEndButton hasAlt handleLeaveCall={toggleModal} />
      </div>
    </div>
  )
}

export default CallControllers
