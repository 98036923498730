import React from 'react'
import PropTypes from 'prop-types'

export const CustomIcons = ({ height, icon, fill, width }) => {
  const Icon = icon

  return (
    <Icon height={height} fill={fill} width={width} />
  )
}

CustomIcons.propTypes = {
  height: PropTypes.string,
  fill: PropTypes.string,
  icon: PropTypes.elementType,
  width: PropTypes.string
}
