/* eslint-disable react/prop-types */
import React, { useState, Fragment } from 'react'

import CustomIcons, { iconList } from 'components/CustomIcons'
import { colors } from 'utils/stylesConstants'

const ChatButton = ({ toggleChatBox, hasAlt = false }) => {
  const [showAltBox, setShowAltBox] = useState(false)

  const iconProps = {
    icon: iconList.Chat,
    fill: colors.PRIMARY
  }

  return (
    <Fragment>
      {
        hasAlt && showAltBox &&
        <div className='AltBox--chat'>
          <p>Chat</p>
        </div>
      }

      <div className={'ChatButton'}
        onClick={toggleChatBox}
        onMouseOver={() => setShowAltBox(true)}
        onMouseOutCapture={() => setShowAltBox(false)}>
        <CustomIcons {...iconProps} />
      </div>
    </Fragment>
  )
}

export default ChatButton
